import * as React from 'react'
import styled from 'styled-components'

import { subtypesValueMap } from '../../styles/config'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { Talent } from 'cc-ui-components'
import { Language } from '../../templates/talent_cv'

const Wrapper = styled.div``

interface Props extends InjectedIntlProps {
  firstName: string
  lastName: string
  subtypes: string[]
  yearsXpTotal: number
  about: string
  profilePicture?: string
  additionalSkills?: Language[]
  location?: string
  joinDate?: string
  availability?: string
}

const BasicInfosSection: React.SFC<Props> = ({
  additionalSkills,
  firstName,
  lastName,
  subtypes,
  about,
  profilePicture,
  location,
  joinDate,
  intl,
  availability,
}) => {
  const item = {
    about,
    profilePicture,
    fullName: `${firstName} ${lastName}`,
    title: subtypes
      ? subtypes.map(subtype => subtypesValueMap[subtype] || subtype).join(', ')
      : '',
    skills: additionalSkills,
  }
  if (joinDate || location) {
    // @ts-ignore
    item.extra = { joinDate, location }
  }
  const isAvailable =
    typeof availability === 'string' && availability !== 'notAvailable'
  return (
    <Wrapper id="cv-page-basic-info">
      <Talent
        {...item}
        big
        isAvailable={isAvailable}
        ctaLink={intl.formatMessage({ id: 'path.get-in-touch' })}
        ctaText={
          isAvailable
            ? `${intl.formatMessage({
                id: 'talent_cv.ct_available',
              })} ${firstName}`
            : intl.formatMessage({ id: 'talent_cv.ct_not_available' })
        }
        availabilityText={`${firstName} ${intl.formatMessage({
          id: `talent_cv.${
            isAvailable
              ? availability === 'fullTime'
                ? 'full_time'
                : 'part_time'
              : 'not'
          }_available_text`,
        })}`}
      />
    </Wrapper>
  )
}

export default injectIntl(BasicInfosSection)
