import * as React from 'react'
import styled from 'styled-components'

import {
  Button,
  IntroductionSection,
  Col,
  Row,
  Container,
  colors,
  media,
} from 'cc-ui-components'
import { changeNavColor } from '../../styles/utils'
import Waypoint from '@clayne/react-waypoint'
import Link from './Link'
import { InjectedIntlProps, injectIntl } from 'react-intl'

export interface StillHaventFoundProps extends InjectedIntlProps {
  primary?: boolean
  height?: string
}

interface StillHaventFoundWrapperProps {
  height?: string
}

const StillHaventFoundWrapper = styled.div`
  background-color: ${colors.lightBackground};
  height: ${(props: StillHaventFoundWrapperProps) => props.height || '260px'};
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.sm`
    height: 346px;
  `};
`

const StillHaventContainer = styled(Container)`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 100%;
  padding: 0;
  ${media.md`
    & > div:first-of-type {
      & > div:first-of-type {
        padding: 0;
        p {
          padding-right: 42.5px;
        }
        h4 {
          margin-right: -3rem;
        }
      }
    }
  `};
  ${media.sm`
    flex-direction: column;
    & > div:first-of-type {
      margin-bottom: 1em;
    }
    div {
      align-items: flex-start;
    }
    p {
      align-self: center;
    }
  `};
`

const StillHaventFound: React.SFC<StillHaventFoundProps> = ({
  intl,
  primary,
  height,
}) => {
  return (
    <Waypoint
      onEnter={() => changeNavColor('light')}
      topOffset={'0'}
      bottomOffset={'99%'}
      scrollableAncestor={'window'}
    >
      <StillHaventFoundWrapper height={height}>
        <Row>
          <Col sm={12} md={12} lg={11.45} center>
            <StillHaventContainer backgroundColor={colors.lightBackground}>
              <IntroductionSection
                background={colors.lightBackground}
                title={intl.formatMessage({ id: 'still.havent.title' })}
                text={intl.formatMessage({ id: 'still.havent.text' })}
                lg={12}
              />
              <Link to={intl.formatMessage({ id: 'path.get-in-touch' })}>
                <Button
                  text={intl.formatMessage({ id: 'still.havent.cta' })}
                  primary={primary}
                  flat
                  medium
                />
              </Link>
            </StillHaventContainer>
          </Col>
        </Row>
      </StillHaventFoundWrapper>
    </Waypoint>
  )
}

export default injectIntl(StillHaventFound)
