import * as React from 'react'
import styled, { css } from 'styled-components'

import SkillsSVG from './assets/icons/skills.svg'

import { brandColors, colors, media, Text } from 'cc-ui-components'

import Card from '../common/Card'
import ProgressBar from '../common/ProgressBar'
import FlatHr from '../common/FlatHr'

import { SkillsType } from '../../templates/talent_cv'

import { FormattedMessage } from 'react-intl'

interface ColumnTitleWrapperProps {
  noMobile?: boolean
}

const SpacedCard = styled(Card)`
  margin-top: 40px;
  background-color: ${colors.inverseText};
  box-shadow: none;
  border: 1px solid ${brandColors.darkgrey2};
  ${media.sm`
    border: none;
    margin-top: 0;
    border-top: 1px solid ${brandColors.silver};
  `};
  ${media.print`
    page-break-inside: avoid;
    box-shadow: none;
    margin-top: 0;
  `};
`

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
`

const LeftSideWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 40px 28px 40px 64px;
  ${media.sm`
    margin: 64px 10px 64px 10px;
  `};
  ${media.print`
    margin: 15px 0 0;
    max-width: 50%;
  `};
`

const SkillsRows = styled.div`
  display: flex;
  flex-direction: row;
`

const Col = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  ${media.sm`
    align-items: flex-start;
    flex: unset;
  `};
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const SpacedRow = styled(Row)`
  height: 28px;
  margin-bottom: 24px;
  ${media.sm`
    height: auto;
    margin-top: 24px;
    margin-bottom: 12px;
    & > div:last-child {
      margin-top: 8px;
    }
  `};
`

const ColumnTitleWrapper = styled.div<ColumnTitleWrapperProps>`
  padding: 40px 0 24px;
  ${media.sm`
    ${(props: ColumnTitleWrapperProps) =>
      props.noMobile ? 'display: none;' : ''}
  `};
  ${media.print`
    padding: 15px 0;
  `};
`

const TitleWrapper = styled.div`
  padding: 6px 0 24px 8px;
  ${media.sm`
    padding-bottom: 16px;
    p {
      font-size: 20px;
      line-height: 30px;
    }
  `};
`

interface SkillNameWrapperProps {
  clickable: boolean
}

const SkillNameWrapper = styled.div`
  ${(props: SkillNameWrapperProps) =>
    props.clickable &&
    css`
      p {
        cursor: pointer;
      }
    `};
  ${media.sm`
    & > p:first-of-type {
      font-size 20px;
      line-height: 24px;
      margin-bottom: 4px;
    }
  `};
`

const YearsWrapper = styled.div``

const MobileYears = styled(Text)`
  display: none;
  text-transform: lowercase;
  font-size: 15px;
  line-height: 24px;
  ${media.sm`
    display: block;
  `};
`

const ColNotMobile = styled(Col)`
  ${media.sm`
    display: none;
  `};
`

const ColWideMobile = styled(Col)`
  ${media.sm`
    flex: 2;
  `};
`

const IconWrapper = styled.div`
  padding-top: 7px;
`

interface Props {
  skills: SkillsType[]
  id?: string
}

interface State {
  viewMore: boolean
}

class SkillsSection extends React.PureComponent<Props, State> {
  public openInNewWindow = (_e: any, link?: string) => {
    if (link) {
      window.open(link, '_blank')
    }
  }

  public render() {
    const { id, skills } = this.props
    if (!skills || !skills.length) {
      return null
    }

    return (
      <SpacedCard id={id}>
        <ContentWrapper>
          <LeftSideWrapper>
            <Row>
              <IconWrapper>
                <SkillsSVG />
              </IconWrapper>
              <TitleWrapper>
                <Text size="xl" uppercase fontWeight={400}>
                  <FormattedMessage id="talent_cv.skill_section.headline" />
                </Text>
              </TitleWrapper>
            </Row>
            <FlatHr />
            <SkillsRows>
              <ColWideMobile>
                <ColumnTitleWrapper noMobile>
                  <Text size="xl" light uppercase fontWeight={400}>
                    <FormattedMessage id="talent_cv.skill_section.techskills" />
                  </Text>
                </ColumnTitleWrapper>
              </ColWideMobile>
              <ColNotMobile>
                <ColumnTitleWrapper>
                  <Text size="xl" light uppercase fontWeight={400}>
                    <FormattedMessage id="talent_cv.skill_section.yearsofxp" />
                  </Text>
                </ColumnTitleWrapper>
              </ColNotMobile>
              <Col>
                <ColumnTitleWrapper noMobile>
                  <Text size="xl" light uppercase fontWeight={400}>
                    <FormattedMessage id="talent_cv.skill_section.proficiency" />
                  </Text>
                </ColumnTitleWrapper>
              </Col>
            </SkillsRows>
            {skills.map((skill, key) => (
              <SpacedRow key={`skill-row-${key}`}>
                <ColWideMobile>
                  <SkillNameWrapper
                    onClick={(e: any) =>
                      this.openInNewWindow(e, skill.externalLink)
                    }
                    clickable={!!skill.externalLink}
                  >
                    <Text size="xl" fontWeight={400}>
                      {skill.skill}
                    </Text>
                    <MobileYears size="md">
                      {skill.years}{' '}
                      <FormattedMessage id="talent_cv.skill_section.yearsofxp" />
                    </MobileYears>
                  </SkillNameWrapper>
                </ColWideMobile>
                <ColNotMobile>
                  <YearsWrapper>
                    <Text size="xl" fontWeight={400}>
                      {skill.years}
                    </Text>
                  </YearsWrapper>
                </ColNotMobile>
                <Col>
                  <ProgressBar progress={skill.rating} />
                </Col>
              </SpacedRow>
            ))}
          </LeftSideWrapper>
        </ContentWrapper>
      </SpacedCard>
    )
  }
}

export default SkillsSection
