import styled from 'styled-components'
import { brandColors } from 'cc-ui-components'
import { media } from 'cc-ui-components/dist'

export default styled.div`
  height: 1px;
  width: 100%;
  background: ${brandColors.darkgrey2};
  ${media.sm`
    background: ${brandColors.silver};
  `};
`
