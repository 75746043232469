import * as React from 'react'
import styled from 'styled-components'

import DevicesSVG from './assets/icons/devices.svg'
import { PositionsType } from '../../templates/talent_cv'
import Card from '../common/Card'
import FlatHr from '../common/FlatHr'
import {
  brandColors,
  colors,
  Text,
  Row as DefaultRow,
  media,
} from 'cc-ui-components'
import WorkExperiencePeriod from './WorkExperiencePeriod'
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl'

const DarkCard = styled(Card)`
  margin-top: 64px;
  background-color: ${colors.inverseText};
  box-shadow: none;
  border: 1px solid ${brandColors.darkgrey2};
  ${media.print`
    page-break-inside: avoid;
    margin-top: 0;
  `};
  ${media.sm`
    border: none;
    margin-top: 0;
    border-top: 1px solid ${brandColors.silver};
  `};
`

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 40px 40px 40px 64px;
  ${media.sm`
    margin: 64px 10px 30px 10px;
  `};
  ${media.print`
    margin-left: 10px;
    margin-top: 15px;
  `};
`

const TitleWrapper = styled.div`
  padding: 6px 0 24px 8px;
  ${media.sm`
    padding-bottom: 16px;
    p {
      font-size: 20px;
      line-height: 30px;
    }
  `};
`

const IconWrapper = styled.div`
  padding: 7px 0 0 15px;
  svg {
    width: 24px;
    height: 24px;
  }
  path {
    fill: ${colors.text};
  }
`

const Row = styled(DefaultRow)`
  ${media.sm`
    flex-wrap: unset;
  `};
`

interface Props extends InjectedIntlProps {
  positions: PositionsType[]
}

const WorkExperienceSection: React.SFC<Props> = ({ intl, positions }) => (
  <DarkCard>
    <ContentWrapper>
      <Row>
        <IconWrapper>
          <DevicesSVG />
        </IconWrapper>
        <TitleWrapper>
          <Text size="xl" uppercase fontWeight={400}>
            <FormattedMessage id="talent_cv.project_section.headline" />
          </Text>
        </TitleWrapper>
      </Row>
      <FlatHr />
      {positions.map((position, key) => (
        <WorkExperiencePeriod
          key={`WorkExperiencePeriod_${key}`}
          jobTitle={position.title}
          workPeriod={`${new Date(position.fromDate).getFullYear()} - ${
            position.isCurrent
              ? intl.formatMessage({ id: 'talent_ct.work_experience.present' })
              : new Date(position.toDate).getFullYear()
          }`}
          company={position.institution}
          infos={
            position.lines && position.lines.length
              ? position.lines
                  .sort((a, b) => {
                    if (!a.order) {
                      return 1
                    }
                    if (!b.order) {
                      return -1
                    }
                    return a.order - b.order
                  })
                  .map(pos => pos.content)
              : position.description
                ? position.description.split('<br/>')
                : []
          }
        />
      ))}
    </ContentWrapper>
  </DarkCard>
)

export default injectIntl(WorkExperienceSection)
