import * as React from 'react'

import Page from '../components/Page'
//import * as SegmentAnalytics from '../lib/segment'

import BasicInfosSection from '../components/profile/BasicInfosSection'
import SkillsSection from '../components/profile/SkillsSection'
import WorkExperienceSection from '../components/profile/WorkExperienceSection'
import EducationCertificationsSection from '../components/profile/EducationCertificationsSection'
import LanguagesSection from '../components/profile/LanguagesSection'
import PopularSearchesSection from '../components/sections/PopularSearchesSection'
import Helmet from 'react-helmet'
import Header from '../components/Header'
import { graphql } from 'gatsby'
import IndexLayout from '../components/layout'
import { RouteComponentProps } from '@reach/router'
import {
  Container as ContainerDefault,
  spacing,
  colors,
  media,
} from 'cc-ui-components'
import styled from 'styled-components'
import StillHaventFound from '../components/common/StillHaventFound'

export interface PositionsType {
  title: string
  fromDate: string
  toDate: string
  isCurrent: boolean
  institution: string
  description: string
  lines?: PositionLine[]
}

export interface SkillsType {
  skill: string
  years: number
  rating: number
  core: boolean
  externalLink?: string
}

export interface EducationsType {
  toDate: string
  fromDate: string
  title: string
  institution: string
  isCurrent: boolean
}

export interface LanguagesType {
  skill: string
  rating: number
}

interface PositionLine {
  content: string
  order?: number
}

interface TalentTemplateProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
        author: {
          name: string
          url: string
        }
      }
    }
    talent: {
      availability: string
      currentCity: string
      currentCountry: string
      isIndexable: boolean
      slug: string
      firstName: string
      lastName: string
      subtypes: string[]
      about: string
      yearsXpTotal: number
      positions: PositionsType[]
      skills: SkillsType[]
      educations: EducationsType[]
      languages: LanguagesType[]
      profilePhotoUrl?: string
    }
    allContentfulTalentSkill: {
      edges: ({ node: { externalLink?: string; name: string } })[]
    }
  }
}

const Container = styled(ContainerDefault)`
  ${media.print`
    background-color: ${colors.inverseText};
  `};
  ${media.sm`
    &:first-of-type {
      padding-top: 65px;
    }
  `};
`

const StillHaventFoundWrapper = styled.div`
  background: ${colors.bodyBackground};
  padding-bottom: 55px;
  padding-top: 104px;
  ${media.sm`
    padding: 64px 0;
    a {
      align-self: flex-start;
      margin-left: 12px;
      margin-top: 25px;
      height: 46px;
      div {
        height: 46px;
      }
    }
  `};
`

const BgDesktop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 678px;
  background-color: ${colors.lightBackground};
  ${media.sm`
    display: none;
  `};
`

export interface Language {
  name: string
  externalLink?: string
}

class TalentTemplate extends React.Component<
  TalentTemplateProps & RouteComponentProps
> {
  /*componentDidMount(): void {
    const {
      data: {
        talent: { firstName, lastName },
      },
    } = this.props

    requestAnimationFrame(() => {
      const title = `${firstName} ${lastName} | at CodeControl`
      SegmentAnalytics.page(`Talent / ${title}`)
    })
  }*/

  public sortElements = (a: any, b: any) => {
    if (a.toDate === null && b.toDate === null) {
      // @ts-ignore
      return new Date(b.fromDate) - new Date(a.fromDate)
    }
    if (a.toDate === null) {
      return -1
    }
    if (b.toDate === null) {
      return 1
    }
    // @ts-ignore
    return new Date(b.toDate) - new Date(a.toDate)
  }

  public sortByDates = (values: any[]) =>
    values && values.sort(this.sortElements)

  public render(): React.ReactNode {
    const { data, location } = this.props
    const {
      talent: {
        availability,
        isIndexable,
        firstName,
        lastName,
        subtypes,
        about,
        yearsXpTotal,
        positions,
        educations,
        languages,
        currentCountry,
        currentCity,
        profilePhotoUrl,
      },
      allContentfulTalentSkill,
    } = data
    let {
      talent: { skills },
    } = data
    skills = skills.map(skill => {
      const skillLink = allContentfulTalentSkill.edges.find(
        (talentSkill: any) => {
          return !!(
            talentSkill.node.name === skill.skill &&
            talentSkill.node.externalLink
          )
        }
      )
      return {
        ...skill,
        externalLink: skillLink ? skillLink.node.externalLink : undefined,
      }
    })

    const keySkills: SkillsType[] = []
    const additionalSkills: Language[] = []
    skills.forEach(skill => {
      if (skill.core && skill.years !== null && skill.rating !== null) {
        keySkills.push(skill)
      } else {
        additionalSkills.push({
          name: skill.skill,
          externalLink: skill.externalLink,
        })
      }
    })

    let talentLocation = currentCountry || currentCountry || ''
    if (currentCity && currentCountry) {
      talentLocation = `${currentCountry}, ${currentCity}`
    }

    const title = `${firstName} ${lastName} | at CodeControl`

    return (
      <IndexLayout location={location}>
        <Page id="cv-page">
          <BgDesktop />
          <Helmet>
            <title>{title}</title>
            <meta name="description" content={about} />
            {!isIndexable && <meta name="ROBOTS" content="NOINDEX,NOFOLLOW" />}
          </Helmet>
          <Header light />
          <Container
            padingsVertical={['170px', 0]}
            backgroundColor={'transparent'}
          >
            <BasicInfosSection
              firstName={firstName}
              lastName={lastName}
              subtypes={subtypes}
              about={about}
              profilePicture={profilePhotoUrl}
              yearsXpTotal={yearsXpTotal}
              additionalSkills={additionalSkills}
              location={talentLocation}
              availability={availability}
            />
          </Container>
          <Container
            padingsVertical={[spacing(5), 0]}
            backgroundColor={colors.bodyBackground}
          >
            <SkillsSection skills={keySkills} id="cv-page-skills" />
            <WorkExperienceSection positions={this.sortByDates(positions)} />
            {educations && educations.length ? (
              <EducationCertificationsSection
                educations={this.sortByDates(educations)}
              />
            ) : null}
            {languages && languages.length ? (
              <LanguagesSection languages={languages} />
            ) : null}
          </Container>
          <StillHaventFoundWrapper>
            <StillHaventFound height={'160px'} />
          </StillHaventFoundWrapper>
          <PopularSearchesSection backgroundColor={colors.bodyBackground} />
        </Page>
      </IndexLayout>
    )
  }
}
export default TalentTemplate

export const query = graphql`
  query TalentTemplateQuery($slug: String!) {
    talent(fields: { slug: { eq: $slug } }) {
      slug
      availability
      isIndexable
      firstName
      lastName
      subtypes
      about
      profilePhotoUrl
      yearsXpTotal
      currentCountry
      currentCity
      positions {
        title
        fromDate
        toDate
        isCurrent
        institution
        description
        lines {
          content
          order
        }
      }
      skills {
        skill
        years
        rating
        core
      }
      educations {
        toDate
        fromDate
        title
        institution
        isCurrent
      }
      languages {
        skill
        rating
      }
    }
    allContentfulTalentSkill(filter: { externalLink: { ne: null } }) {
      edges {
        node {
          ...contentfulTalentSkill
        }
      }
    }
  }
`
