import * as React from 'react'
import styled from 'styled-components'

import EducationCertification from './EducationCertification'
import EducationSVG from './assets/icons/education.svg'

import Card from '../common/Card'
import FlatHr from '../common/FlatHr'
import { brandColors, colors, media, Text } from 'cc-ui-components'

import { EducationsType } from '../../templates/talent_cv'
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl'

const DarkCard = styled(Card)`
  margin-top: 64px;
  background-color: ${colors.inverseText};
  box-shadow: none;
  border: 1px solid ${brandColors.darkgrey2};
  ${media.sm`
    margin-top: 0;
    border: none;
    border-top: 1px solid ${brandColors.silver};
  `};
  ${media.print`
    page-break-inside: avoid;
  `};
`

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 40px 40px 40px 64px;
  ${media.sm`
    margin: 64px 10px 30px 10px;
  `};
  ${media.print`
    margin-left: 10px;
  `};
`

const SubsectionWrapper = styled.div`
  display: flex;
`

const IconWrapper = styled.div`
  margin-top: 7.5px;
  svg {
    width: 24px;
    height: 24px;
  }
  path {
    fill: ${colors.text};
  }
`

const TitleWrapper = styled.div`
  padding: 6px 0 24px 8px;
  ${media.sm`
    padding-bottom: 16px;
    p {
      font-size: 20px;
      line-height: 30px;
    }
  `};
`

interface Props extends InjectedIntlProps {
  educations: EducationsType[]
}

const EducationCertificationsSection: React.SFC<Props> = ({
  intl,
  educations,
}) => (
  <DarkCard>
    <ContentWrapper>
      <SubsectionWrapper>
        <IconWrapper>
          <EducationSVG />
        </IconWrapper>
        <TitleWrapper>
          <Text size="xl" uppercase fontWeight={400}>
            <FormattedMessage id="talent_cv.education_section.headline" />
          </Text>
        </TitleWrapper>
      </SubsectionWrapper>
      <FlatHr />
      {educations.map((education, key) => (
        <EducationCertification
          key={`EducationCertification_${key}`}
          title={education.title}
          period={`${new Date(education.fromDate).getFullYear()} - ${
            education.isCurrent
              ? intl.formatMessage({ id: 'talent_ct.work_experience.present' })
              : new Date(education.toDate).getFullYear()
          }`}
          institute={education.institution}
        />
      ))}
    </ContentWrapper>
  </DarkCard>
)

export default injectIntl(EducationCertificationsSection)
