import * as React from 'react'
import styled from 'styled-components'

import { colors, media } from 'cc-ui-components'

interface SquareProps {
  filled?: boolean
}

const Wrapper = styled.div`
  background-color: transparent;
  display: flex;
  width: 144px;
  flex-direction: row;
  justify-content: space-between;
  ${media.sm`
    width: 72px;
  `};
`

const Square = styled.div`
  background-color: ${(props: SquareProps) =>
    props.filled ? colors.darkBackground : 'transparent'};
  border: 1px solid ${colors.darkBackground};
  width: 15px;
  height: 15px;
  ${media.sm`
    width: 7px;
    height: 7px;
  `};
`

interface ProgressBarProps {
  progress: number
}

const ProgressBar: React.SFC<ProgressBarProps> = ({ progress, ...rest }) => (
  <Wrapper {...rest}>
    <Square filled={progress >= 1} />
    <Square filled={progress >= 2} />
    <Square filled={progress >= 3} />
    <Square filled={progress >= 4} />
    <Square filled={progress >= 5} />
  </Wrapper>
)

export default ProgressBar
