import * as React from 'react'
import styled from 'styled-components'

import LanguageItem from './LanguageItem'

import Card from '../common/Card'
import { brandColors, colors, media, Text, BalloonIcon } from 'cc-ui-components'
import FlatHr from '../common/FlatHr'

import { LanguagesType } from '../../templates/talent_cv'
import { FormattedMessage } from 'react-intl'

const SpacedCard = styled(Card)`
  margin-top: 64px;
  background-color: ${colors.inverseText};
  box-shadow: none;
  border: 1px solid ${brandColors.darkgrey2};
  ${media.print`
    page-break-inside: avoid;
  `};
  ${media.sm`
    border: none;
    margin-top: 0;
    border-top: 1px solid ${brandColors.silver};
  `};
`

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 30px 48px 60px 48px;
  ${media.sm`
    margin: 64px 10px 64px 10px;
  `};
  ${media.print`
    margin-left: 10px;
    margin-right: 25px;
  `};
`

const InnerWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-between;
  ${media.sm`
    flex-direction: column;
  `};
`

const SubsectionWrapper = styled.div`
  display: flex;
  padding-bottom: 24px;
  ${media.sm`
    padding-bottom: 0;
  `};
`

const IconWrapper = styled.div`
  margin-top: 7px;
  svg {
    width: 24px;
    height: 24px;
  }
`

const TitleWrapper = styled.div`
  padding: 6px 0 24px 8px;
  ${media.sm`
    padding-bottom: 16px;
    p {
      font-size: 20px;
      line-height: 30px;
    }
  `};
`

const idioms = {
  en: 'ENGLISH',
  pt: 'PORTUGUESE',
  fr: 'FRENCH',
  1: 'Elementary',
  2: 'Limited working',
  3: 'Professional working',
  4: 'Full Professional',
  5: 'Native or bilingual',
}

interface Props {
  languages: LanguagesType[]
}

const LanguagesSection: React.SFC<Props> = ({ languages }) => (
  <SpacedCard>
    <ContentWrapper>
      <SubsectionWrapper>
        <IconWrapper>
          <BalloonIcon />
        </IconWrapper>
        <TitleWrapper>
          <Text size="xl" uppercase fontWeight={400}>
            <FormattedMessage id="talent_cv.language_section.headline" />
          </Text>
        </TitleWrapper>
      </SubsectionWrapper>
      <FlatHr />
      <InnerWrapper>
        {languages.map((language, key) => (
          <LanguageItem
            key={`LanguageItem_${key}`}
            idiom={language.skill}
            proficiency={(idioms as any)[language.rating]}
          />
        ))}
      </InnerWrapper>
    </ContentWrapper>
  </SpacedCard>
)

export default LanguagesSection
