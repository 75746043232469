import * as React from 'react'
import styled from 'styled-components'

import { media, Text } from 'cc-ui-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  ${media.sm`
    margin-top: 24px;
  `};
`

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  ${media.sm`
    flex-direction: column-reverse;
    p {
      &:first-child {
        font-size: 20px;
        line-height: 28px;
      }
      &:last-child {
        font-size: 15px;
        line-height: 24px;
      }
    }
  `};
`

interface Props {
  title: string
  period: string
  institute: string
}

const EducationCertification: React.SFC<Props> = ({
  title,
  period,
  institute,
}) => (
  <Wrapper>
    <InnerWrapper>
      <Text size="xl" fontWeight={400}>
        {title}
      </Text>
      <Text size="md" light>
        {period}
      </Text>
    </InnerWrapper>
    <InnerWrapper>
      <Text uppercase light size="md">
        {institute}
      </Text>
    </InnerWrapper>
  </Wrapper>
)

export default EducationCertification
