import * as React from 'react'
import styled from 'styled-components'

import { colors, media } from 'cc-ui-components'
import FlatHr from '../common/FlatHr'

const Wrapper = styled.div`
  display: flex;
  margin-top: 40px;
  width: 48%;
  margin-right: 5px;
  ${media.sm`
    width: 100%;
    margin-top: 17px;
    flex-direction: column;
    margin-right: 0;
    &:first-child {
      & > div:first-of-type {
        display: none;
      }
    }
  `};
`

const Idiom = styled.p`
  width: 122px;
  color: ${colors.text};
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  margin: 0;
  ${media.sm`
    width: 100%;
  `};
`

const Proficiency = styled.p`
  color: ${colors.text};
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  margin: 0 0 0 25px;
  ${media.sm`
    color: ${colors.lightText};
    font-size: 15px;
    line-height: 24px;
    font-weight: 300;
    text-transform: uppercase;
    margin-left: 0;
    margin-top: 8px;
  `};
`

const MobileHrWrapper = styled.div`
  display: none;
  width: 100vw;
  margin-bottom: 15px;
  ${media.sm`
    display: block;
  `};
`

interface Props {
  idiom: string
  proficiency: string
}

const LanguageItem: React.SFC<Props> = ({ idiom, proficiency }) => (
  <Wrapper>
    <MobileHrWrapper>
      <FlatHr />
    </MobileHrWrapper>
    <Idiom>{idiom}</Idiom>
    <Proficiency>{proficiency}</Proficiency>
  </Wrapper>
)
export default LanguageItem
