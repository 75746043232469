import * as React from 'react'
import styled from 'styled-components'
import { media } from 'cc-ui-components'

const Wrapper = styled.div`
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  ${media.print`
    page-break-inside: avoid;
    box-shadow: none;
  `};
`
interface CardProps {
  children: {}
  id?: string
}

const Card: React.SFC<CardProps> = ({ children, ...rest }) => (
  <Wrapper {...rest}>{children}</Wrapper>
)

export default Card
