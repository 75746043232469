import * as React from 'react'
import styled from 'styled-components'
import { colors, media, Text } from 'cc-ui-components'
import _ from 'lodash'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0 15px;
  ${media.print`
    margin: 30px 0 0;
  `};
  ${media.sm`
    margin: 24px 0 15px;
  `};
`

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  ${media.sm`
    flex-direction: column-reverse;
    align-items: flex-start;
    padding-bottom: 8px;
     & > p {
      &:first-child {
        font-size: 20px;
        line-height: 24px;
      }
      &:last-child {
        font-size: 15px;
        line-height: 24px;
        margin-bottom: 8px;
      }
    }
  `};
  ${media.print`
    padding-right: 30px;
  `};
`

const CompanyNameWrapper = styled.div`
  padding-bottom: 16px;
  ${media.sm`
    padding-bottom: 24px;
    p {
      font-size: 15px;
      line-height: 24px;
    }
  `};
`

const TopicsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 71%;
  flex: 1;
  ${media.sm`
    width: 100%;
  `};
`

const ListItem = styled.li`
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.46667rem;
  color: ${colors.secondaryText};
  padding-left: 10px;
  a {
    color: ${colors.primary};
    text-decoration: none;
  }
`

const ExperienceList = styled.ul`
  margin: 10px 0 0 10px;
  padding-left: 23px;
`

interface Props {
  jobTitle: string
  workPeriod: string
  company: string
  infos: string[]
}

const linkRe = /(https?:\/\/[^\s|^)]+)/

const WorkExperiencePeriod: React.SFC<Props> = ({
  jobTitle,
  workPeriod,
  company,
  infos,
}) => {
  const infosWithLinks = _.map(_.compact(infos), info => {
    return info.replace(
      linkRe,
      (_match, word) => `<a href="${word}" target="_blank">${word}</a>`
    )
  })
  return (
    <Wrapper>
      <TitleWrapper>
        <Text size="xxl" fontWeight={400}>
          {jobTitle}
        </Text>
        <Text size="md" light uppercase>
          {workPeriod}
        </Text>
      </TitleWrapper>
      <CompanyNameWrapper>
        <Text size="md" uppercase light>
          {company}
        </Text>
      </CompanyNameWrapper>
      <TopicsWrapper>
        <ExperienceList>
          {infosWithLinks.map((info, key) => (
            <ListItem
              key={`ListItem_${key}`}
              dangerouslySetInnerHTML={{ __html: info }}
            />
          ))}
        </ExperienceList>
      </TopicsWrapper>
    </Wrapper>
  )
}

export default WorkExperiencePeriod
